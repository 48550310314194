import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout/Layout'
import BannerImage from '../components/Page/BannerImage'

// markup
const NotFoundPage = ({data}) => {
	return (
		<Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
				<div className="md:w-1/2 p-4 md:p-12">
					<h1 className={`text-5xl mb-4`}>404 Page Not Found</h1>
					<p className={`text-xl leading-relaxed mb-4`}>Samuel Johnson looked everywhere, but sadly he could not find the page you requested.</p>
					<p className={`text-xl leading-relaxed`}>Let James Boswell <Link to={`/`} className={`text-blue-500`}>take you home</Link>.</p>
				</div>
			</section>
		</Layout>
    );
}

export const query = graphql`{
  file(relativePath: {eq: "general/Samuel-Johnson-by-Joshua-Reynolds.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default NotFoundPage
